import React, { useState } from "react"
import {
  Grid,
  Typography,
  TextField,
  Hidden,
  Box,
  Button,
} from "@material-ui/core"
import CustomTourContainer from "../components/CustomTour/CustomTourContainer"
import { makeStyles } from "@material-ui/core/styles"
import LocalizedLink from "../components/LocalizedLink"
import { useSelector } from "react-redux"
import { SEO } from "../components/SEO"

const CustomTextField = ({ payload }) => {
  const { required, label, placeholder, value, setFunc } = payload
  return (
    <TextField
      required={required}
      placeholder={placeholder}
      fullWidth
      label={label}
      InputLabelProps={{
        shrink: true,
      }}
      value={value}
      onChange={event => setFunc(event.target.value)}
    />
  )
}

const useStyles = makeStyles(theme => ({
  cardStyle: {
    margin: theme.spacing(2),
    width: "100%",
  },
  logo: {
    fontFamily: "MabryProBold",
    fontSize: "1.5rem",
    fontWeight: "bold",
    padding: 0,
    lineHeight: "24px",
  },
  pgNumStyle: {
    fontSize: "2rem",
    lineHeight: "32px",
  },
}))

const CustomTour = ({ pageContext, location }) => {
  const itineraryData = location?.state?.itineraryData
  const reqsData = location?.state?.reqsData
  const classes = useStyles()
  const translations = useSelector(
    state => state?.locale?.localeObj?.pageData?.mapScreen
  )

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [wechat, setWechat] = useState("")

  const fieldList = [
    {
      required: true,
      label: translations?.fullName,
      placeholder: translations?.typeYourName,
      value: name,
      setFunc: setName,
    },
    {
      required: true,
      label: translations?.email,
      placeholder: translations?.typeEmail,
      value: email,
      setFunc: setEmail,
    },
    {
      required: true,
      label: translations?.phone,
      placeholder: translations?.typePhone,
      value: phone,
      setFunc: setPhone,
    },
    {
      required: false,
      label: translations?.weChat,
      placeholder: translations?.typeWeChatUsername,
      value: wechat,
      setFunc: setWechat,
    },
  ]

  return (
    <CustomTourContainer
      pageContext={pageContext}
      location={location}
      pgNum={3}
      contactInfo={{ name: name, email: email, phone: phone, wechat: wechat }}
      buttonProps={{
        to: "/QuoteRequested",
        state: {
          itineraryData,
          reqsData,
          contactInfo: {
            name,
            email,
            phone,
            wechat,
          },
        },
        back: "/CreateCustom",
      }}
      showReqs
    >
      <SEO/>
      <Hidden smDown>
        <Grid container spacing={3}>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <Typography variant="h6">{translations?.personalDetails}</Typography>
            {fieldList.map(payload => (
              <CustomTextField key={payload.label} payload={payload} />
            ))}
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
          <Box display="flex" justifyContent="space-between" mb={4}>
            <Button
              component={LocalizedLink}
              to="/"
              disableRipple
              className={classes.logo}
            >
              {translations?.zhutravel}
            </Button>
            <Typography className={classes.pgNumStyle}>{3}&frasl;3</Typography>
          </Box>
          <Grid container spacing={3} >
            <Grid item xs={1} />
            <Grid item xs={12}>
              <Typography variant="h6">{translations?.personalDetails}</Typography>
              {fieldList.map(payload => (
                <CustomTextField key={payload.label} payload={payload} />
              ))}
            </Grid>
          </Grid>
          <Grid container spacing={3} >
            <Grid item xs={1} />
            
          </Grid>
      </Hidden>
    </CustomTourContainer>
  )
}

export default CustomTour
